import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import { alertMessage } from "../actions/auth";
import TextField from "@mui/material/TextField";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";

import { API_URL } from "../actions/auth";

import { themeStyle, themeStyleDark } from "../components/Components";

function Profiles() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [medicines, setMedicines] = useState([]);
  const [ogMedicines, setOGMedicines] = useState([]);
  const [random, setRandom] = useState(0);
  const [search, setSearch] = useState("");
  const [medicine, setMedicine] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedFile, setSelectedFile] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onFileUpload() {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);

    const config = {
      headers: {
        Authorization: state.auth.token,
      },
    };

    formData.append("phone_number", state.auth.user.phone_number);
    formData.append("filename", selectedFile.name);

    axios
      .post(API_URL + "update_xl", formData, config)
      .then(() => {
        createAlert("SUCCESS", "Success.", "Document uploaded.");
        getData();
      })
      .catch((err) => {
        console.log("___");
        console.log(err);
        createAlert("ERROR", "Error", "An error occured.");
      });

    handleClose();
    document.getElementById("contained-button-file").value = "";
  }

  function onFileChange(event) {
    // Update the state
    setSelectedFile(event.target.files[0]);

    handleOpen();
  }

  function onFileClick(event) {
    // Update the state
    document.getElementById("contained-button-file").value = "";
  }

  function fileData() {
    if (selectedFile) {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton size="small">
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{ color: "#3182bd" }}
              />
            </IconButton>
          </div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>

          <br></br>
          <br></br>
          {selectedFile.size > 25 * 1024 * 1024 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  createAlert(
                    "ERROR",
                    "File too big.",
                    "File size should be less than 25 MB."
                  );
                  setOpen(false);
                }}
                variant="contained"
                style={{ backgroundColor: "#ddd" }}
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={() => onFileUpload()}
                color="primary"
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  }

  function handleSearch(text) {
    setSearch(text);
    if (text.length == 0) {
      setMedicines(ogMedicines);
      setSearch("");
      setRandom(makeid(5));
    } else {
      var tempMedicines = [];

      for (var i = 0; i < ogMedicines.length; i++) {
        if (
          ogMedicines[i]["company_name"].toLowerCase().includes(text) ||
          ogMedicines[i]["medicine_name"].toLowerCase().includes(text)
        ) {
          tempMedicines.push(ogMedicines[i]);
        }
      }
      setCurrentPage(1);
      setMedicines(tempMedicines);
      setRandom(makeid(5));
    }
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function handleCurrentPage(val) {
    var tempUsers = Array.from(ogMedicines);
    setSearch("");
    setCurrentPage(val);
    setMedicines(tempUsers.splice((val - 1) * 2000, 2000));
  }

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "MEDICINES"
  );

  function getData() {
    var body = {
      uid: state.auth.user.uid,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "get_medicines", body, config).then((res) => {
      setOGMedicines(res.data);
      setMedicine(res.data[0]);
      setMedicines(res.data.splice(0, 2000));
      setIsLoading(false);
      setIsUploading(false);
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  function getContent() {
    if (selectedPage == "MEDICINES") {
      if (medicines.length == 0) {
        return (
          <div
            style={{
              padding: "100px 50px 90px 115px",
              backgroundColor:
                state.auth.theme == "dark"
                  ? themeStyleDark.backgroundColor
                  : themeStyle.backgroundColor,
              minHeight: "100vh",
              width: "100vw",
              fontSize: "30px",
            }}
          >
            No Medicines yet
          </div>
        );
      }
      return (
        <Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "25px",
            }}
          >
            {Array.from(Array(Math.ceil(ogMedicines.length / 2000)).keys()).map(
              (temp, index) => (
                <div
                  style={{
                    cursor: "pointer",
                    margin: "3px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25px",
                    height: "25px",
                    borderRadius: "30px",
                    color: "white",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                  onClick={() => {
                    handleCurrentPage(index + 1);
                  }}
                >
                  {index + 1}
                </div>
              )
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryCardColor
                    : themeStyle.primaryCardColor,
                borderRadius: "10px",
                padding: "15px",
                boxShadow: "0px 0px 10px #ccc",
              }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  justifyContent: "flex-start",
                  textAlign: "left",
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                  paddingBottom: "20px",
                  borderBottom:
                    state.auth.theme == "dark"
                      ? "1px solid" + themeStyleDark.primaryColor
                      : "1px solid" + themeStyle.primaryColor,
                }}
              >
                <Grid item md={1}>
                  No.
                </Grid>
                <Grid item md={3}>
                  Company Name
                </Grid>
                <Grid item md={5}>
                  Medicine Name
                </Grid>

                <Grid item md={3}>
                  Generic Name
                </Grid>
              </Grid>
              {medicines.length == 0 ? (
                <h1>No medicines found.</h1>
              ) : (
                <Fragment>
                  {medicines.map((medicine, index) => (
                    <Grid
                      onClick={() => setMedicine(medicine)}
                      container
                      spacing={2}
                      style={{
                        color: "black",
                        padding: "15px",
                      }}
                    >
                      <Grid item md={1}>
                        {(currentPage - 1) * 2000 + (index + 1)}
                      </Grid>
                      <Grid
                        style={{ cursor: "pointer" }}
                        onClick={() => {}}
                        item
                        md={3}
                      >
                        {medicine["company_name"]}
                      </Grid>
                      <Grid item md={5}>
                        {medicine["medicine_name"]}
                      </Grid>

                      <Grid item md={3} style={{ textAlign: "center" }}>
                        {medicine["generic_name"]}
                      </Grid>
                    </Grid>
                  ))}
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "30%",
            }}
          >
            <label
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              htmlFor="contained-button-file"
            >
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                onChange={(e) => onFileChange(e)}
              />
              <img
                style={{ maxHeight: "256px", margin: "100px 0px 25px 0px" }}
                src={require("../img/xl.png")}
              />
              <Button
                style={{
                  width: "100%",
                  fontSize: "25px",
                  height: "50px",
                  marginTop: "-1px",
                  backgroundColor: "#3182bd",
                  fontFamily: "Bahnschrift",
                }}
                variant="contained"
                component="span"
              >
                Upload Latest XL File
              </Button>
              <br />
              <br />
              {isUploading ? <CircularProgress /> : ""}
            </label>
          </div>
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                maxWidth: "88vw",
                marginTop: "45vh",
                marginLeft: "8vw",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar
              tabs={
                <Grid container spacing={1.5} justifyContent={"flex-end"}>
                  <Grid item md={4}>
                    {selectedPage == "MEDICINES" ? (
                      <Button
                        fullWidth
                        value={"MEDICINES"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          MEDICINES
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value={"MEDICINES"}
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#d7ecff",
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          MEDICINES
                        </h3>
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    {selectedPage == "UPLOAD" ? (
                      <Button
                        fullWidth
                        value="UPLOAD"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                          }}
                        >
                          UPLOAD
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="UPLOAD"
                        onClick={handlePageSelect}
                        p={2}
                        style={{
                          backgroundColor: "#d7ecff",
                          color: "white",
                          height: "30px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          UPLOAD
                        </h3>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              }
            />
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    backgroundColor: "white",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    padding: "20px",
                  }}
                >
                  {fileData()}
                </div>
              </Fade>
            </Modal>

            <div
              style={{
                padding: "100px 50px 90px 115px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                minHeight: "100vh",
                width: "100vw",
              }}
            >
              {getContent()}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Profiles;
